import React, { useState, useEffect, lazy, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import './Base.css';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import {faVimeo} from '@fortawesome/free-brands-svg-icons';
import { Icon } from '@iconify-icon/react';


// Lazy-loaded components
const Home = lazy(() => import('../Home/Home'));
const About = lazy(() => import('../About/About'));
const Press = lazy(() => import('../Press/Press'));
const Gallery = lazy(() => import('../Gallery/Gallery'));
const Contact = lazy(() => import('../Contact/Contact'));
const DrawingMentor = lazy(() => import('../DrawingMentor/DrawingMentor'));
const Subscribed = lazy(() => import('../Subscribed/Subscribed'));




const Base = () => {



  const [currentPage, setCurrentPage] = useState(''); // State to track current page
  const location = useLocation();
  const [navbar, setNavbar] = useState(false);
  const [showWorks, setShowWorks] = useState(false);
  const [home, setHome] = useState(false);
  const [about, setAbout] = useState(false);
  const [showAbout, setShowAbout] = useState(false);




  function toggleNavbar() {
    setNavbar(!navbar)
  }

  function toggleWorks() {
    setShowWorks(!showWorks);
    if (showAbout) {
      toggleAbout()
    }
  };

  function toggleAbout() {
    setShowAbout(!showAbout);
    if (showWorks) {
      toggleWorks()
    }
  }


  function itemClicked(event) {
    if (navbar) {
      toggleNavbar();
    }
    if (showWorks) {
      toggleWorks()
    }
    if (showAbout) {
      toggleAbout()
    }
    

    const item = event.currentTarget;
    item.classList.add('selectedItem');

    const items = document.querySelectorAll('.item');
    items.forEach(otherItem => {
      if (otherItem !== item) {
        otherItem.classList.remove('selectedItem');
      }
      const worksItems = document.querySelectorAll('.worksItem');
      worksItems.forEach(worksItem => {
        worksItem.classList.remove('selectedWork');
      })
      const aboutItems = document.querySelectorAll('.aboutItem');
      aboutItems.forEach(aboutItem => {
        aboutItem.classList.remove('selectedAbout');
      })

    });
  }


  function worksItemClicked(event) {
    if (navbar) {
      toggleNavbar();
    }
    if (showAbout) {
      toggleAbout()
    }
    const worksItem = event.currentTarget;
    worksItem.classList.add('selectedWork')

    const worksItems = document.querySelectorAll('.worksItem');
    worksItems.forEach(otherworksItem => {
      if (otherworksItem !== worksItem) {
        otherworksItem.classList.remove('selectedWork');
      }
      const items = document.querySelectorAll('.item');
      items.forEach(item => {
        item.classList.remove('selectedItem');
      })
      const aboutItems = document.querySelectorAll('.aboutItem');
      aboutItems.forEach(aboutItem => {
        aboutItem.classList.remove('selectedAbout');
      })

    })

  }

  function aboutItemClicked(event) {
    if (navbar) {
      toggleNavbar();
    }
    if (showWorks) {
      toggleWorks()
    }

    const aboutItem = event.currentTarget;
    aboutItem.classList.add('selectedAbout');

    const aboutItems = document.querySelectorAll('.aboutItem');
    aboutItems.forEach(otherAboutItems => {
      if (otherAboutItems !== aboutItem) {
        otherAboutItems.classList.remove('selectedAbout');
      }
    })
    const worksItems = document.querySelectorAll('.worksItem');
    worksItems.forEach(worksItem => {
      worksItem.classList.remove('selectedWork');
    })
    const items = document.querySelectorAll('.item');
    items.forEach(item => {
      item.classList.remove('selectedItem');
    })
  }



  useEffect(() => {

    const hamburger = document.querySelector('.hamburger');
    hamburger.addEventListener('click', toggleNavbar);

    const items = document.querySelectorAll('.item')
    const worksItems = document.querySelectorAll('.worksItem');
    const aboutItems = document.querySelectorAll('.aboutItem')

    items.forEach(item => {
      item.addEventListener('click', itemClicked)
    })

    worksItems.forEach(worksItem => {
      worksItem.addEventListener('click', worksItemClicked)
    })

    aboutItems.forEach(aboutItem => {
      aboutItem.addEventListener('click', aboutItemClicked)
    })

    const worksSubheading = document.querySelector('.worksSubHeading');
    worksSubheading.addEventListener('click', toggleWorks);

    const aboutSubHeading = document.querySelector('.aboutSubHeading');
    aboutSubHeading.addEventListener('click', toggleAbout);


    return () => {

      hamburger.removeEventListener('click', toggleNavbar);

      items.forEach(item => {
        item.removeEventListener('click', itemClicked)
      })

      worksItems.forEach(worksItem => {
        worksItem.removeEventListener('click', worksItemClicked)
      })

      worksSubheading.removeEventListener('click', toggleWorks);
      aboutSubHeading.removeEventListener('click', toggleAbout);

    };
    // hamburger click working when no dependancy OR navabar dependency. Empty dependency NOT WORKING
  })

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === "/Home") {
      setHome(true);
    } else {
      setHome(false);
    }
    if (pathname === "/About") {
      setAbout(true);
    } else {
      setAbout(false);
    }
    const formattedPageName = pathname.replace(/-/g, ' ').toLowerCase(); // Replace hyphens with spaces
    setCurrentPage(formattedPageName.substring(1) || ''); // Set current page based on formatted page name
// eslint-disable-next-line
  }, [location])

  return (
    <div>
      {/* // <!-- NAVBAR --> */}

      <nav className={`navBar ${navbar ? "isActive" : null}`}>


        {/* <!-- NAV content --> */}
<div className="itemsIconsWrap">
  
          <div className={`itemsWrap ${showAbout||showWorks ? "itemsWrapPaddingTop" : ""}`} id="itemsWrap">
  
            {/* <div className="itemsr1"> */}
            {/* <Link to="/" className='item home'>Home</Link> */}
            <div className="subHeadingWrap">
              <span className='aboutSubHeading' style={{ borderBottom: showAbout ? "1px solid black" : "" }}>about {navbar ? showAbout ? <i class="fa-solid fa-chevron-up"></i> : <i class="fa-solid fa-chevron-down"></i> : ""}</span>
              <div className={`about ${showAbout ? "aboutON" : "aboutOFF"}`}>
                <ul>
                  <li className="aboutItem">
                    <Link to="/Biography" href="">biography</Link>
                  </li>
                  <li className="aboutItem">
                    <Link to="/CV" href="">cv</Link>
                  </li>
                  <li className="aboutItem">
                    <Link to="/Statement" href="">statement</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="subHeadingWrap">
              <span className="worksSubHeading" style={{ borderBottom: showWorks ? "1px solid black" : "" }}>paintings {navbar ? showWorks ? <i class="fa-solid fa-chevron-up"></i> : <i class="fa-solid fa-chevron-down"></i> : ""}</span>
              <div className={`works ${showWorks ? 'worksON' : 'worksOFF'}`}>
  
                <ul>
  
                  <li className="worksItem" id="Unknown-Cities">
                    <Link to="/Unknown-Cities">
                      unknown cities
                    </Link>
                  </li>
                  <li className="worksItem" id="Rewilding">
                    <Link to="/Rewilding">
                      rewilding
                    </Link>
                  </li>
                  <li className="worksItem" id="Dwellers">
                    <Link to="/Dwellers">
                      dwellers
                    </Link>
                  </li>
                  <li className="worksItem" id="The-Shipping-Log">
                    <Link to="/The-Shipping-Log">
                      the shipping log
                    </Link>
                  </li>
                </ul>
              </div>
  
            </div>
  
  
  
  
  
            <Link to="/Drawing" className="item">drawing</Link>
            <Link to="/DrawingMentor" className="item">drawing mentor</Link>
            <Link to="/Performance" className="item">performance</Link>
  
            <Link to="/Press" className="item">press</Link>
            <Link to="/Contact" className='item'>contact</Link>
            {/* <Link to="/ArtistsStatement" className='item'>Artist's Statement</Link> */}
  
          </div>
  
          <div className="iconsWrap">
              <div className="icon">
                <a target='_blank' without rel="noreferrer" href="https://www.instagram.com/dianapage15/">
                  <FontAwesomeIcon icon={faInstagram} size='lg' />
                </a>
              </div>
              <div className="icon">
                <a target='_blank' without rel="noreferrer" href="https://www.facebook.com/dianapage.studio/?baseModal=admin_todo_tour">
                  <FontAwesomeIcon icon={faFacebook} size='lg' />
                </a>
              </div>
  
              <div className="icon">
                <a target='_blank' without rel="noreferrer" href="https://vimeo.com/361478589">
                <FontAwesomeIcon icon={faVimeo} size='lg' />
                </a>
              </div>

              <div className="icon">
                <a target='_blank' without rel="noreferrer" href="https://substack.com/@dianapage">
                  <Icon icon="simple-icons:substack" style={{ color: "black" }} />
                </a>
              </div>

            </div>
</div>






        <div className="titleWrap">
          <a href="/">
            {/* <h1 className='title'>DIANA PAGE</h1> */}
            <img className="dianalogo" src={home ? "/Untitled-3.png" : "/logopink.png"} style={home ? {} : { marginTop: "20px" }} alt='diana'></img>
          </a>
        </div>


      </nav>
      {/* <!-- NAV toggle --> */}
      <div className={`hamburgerWrap ${navbar ? "isActive" : null}`}>
        <a href="/">
          <h1 className={home ? '' : 'hamburgerTitle'}>DIANA PAGE</h1>
        </a>
        <div className="currentWork">

          {/* <h4>{currentPage}</h4> */}
        </div>
        {/* Toggle works only if works is already open */}
        <button className={`hamburger ${navbar ? "isActive" : null}`}  >
          <div className="bar"></div>
        </button>
      </div>

      {/* Only apply contentMarginTop if current page is home */}
      <div className={`content  ${about ? "contentMarginTopNone" : ""} ${home ? "overflowHidden" : ""} ${home ? "" : "contentMarginTop"}`}>

        <Suspense>
          <Routes>
            <Route path='/' element={<Navigate to="/Home" replace />} />
            <Route path='/Home' element={<Home />}></Route>
          
            <Route path='/CV' element={<About page={"CV"} />}></Route>
            <Route path='/Press' element={<Press pageName={currentPage} />}></Route>
            <Route path="/Biography" element={<About page={"Biography"} />}></Route>
            <Route path="/Statement" element={<About page={"Statement"} />}></Route>
          
            <Route path="/Unknown-Cities" element={<Gallery work="Unknown-Cities" pageName={currentPage} />} />
            <Route path="/Rewilding" element={<Gallery work="Rewilding" pageName={currentPage} />} />
            <Route path="/Dwellers" element={<Gallery work="Dwellers" pageName={currentPage} />} />
            <Route path="/The-Shipping-Log" element={<Gallery work="The-Shipping-Log" pageName={currentPage} />} />
            <Route path="/Drawing" element={<Gallery work="Drawing" pageName={currentPage} />} />
            <Route path="/Performance" element={<Gallery work="Performance" pageName={currentPage} />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/DrawingMentor" element={<DrawingMentor />} />
            <Route path="/Subscribed" element={<Subscribed/>}></Route>
          </Routes>
        </Suspense>





      </div>





    </div>
  )
}

export default Base
